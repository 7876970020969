import React from "react";
import MenuTitle from "../MainComponents/MenuTitle";
import MenuEtapa from "../Etapas/Etapa";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

export default function TipoEtapa() {
  let history = useHistory();

  function handleClick() {
    history.goBack();
  }
  return (
    <>
      <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
      <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>
      <Link to={{ pathname: "/home" }}>
        <img src="logo.png"/>
      </Link>
      <div class="MainMenu-btncont"></div>
    </div>
      <MenuEtapa />
    </>
  );
}
