import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as aq from "arquero";
import { useHistory } from "react-router";
import "./Etapas.css";

export default function EtapasMenu() {
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [texto, setTexto] = useState(null);

  const getCsv = async () => {
    const csv = await fetch(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vSrk1FXIL841yRQfzBpTdJS8wXzn0f69Rzgy31VwTe0-HE-vk9cRvsEhtfXNuIuxtPOkSy0d_aO0o41/pub?output=csv"
    ).then((res) => res.text());

    const data = aq.fromCSV(csv);
    const dataDic = [];

    const index = data.array("Index");
    const imagen = data.array("Imagen");

    for (let i in index) {
      if (index[i] === null) index[i] = 0;
      if (imagen[i] === null) imagen[i] = false;

      dataDic.push({
        Index: index[i],
        Imagen: imagen[i],
        Texto: data.array("Texto")[i],
      });
    }

    setData(dataDic);
  };

  let checklist = [];
  let checktext = [];

  const getCheck = (data) => {
    data?.forEach((item) => {
      if (item.Index !== 0) {
        item.Imagen = item.Imagen
          .replace("https://drive.google.com/file/d/", "")
          .replace("/view?usp=sharing", "");
        checklist.push(item);
        setList(checklist);
      } else {
        checktext = item;
        setTexto(checktext);
      }
    });
  };

  useEffect(() => {
    getCheck(data);
  }, [data]);

  useEffect(() => {
    if (!data) getCsv();
  }, [data]);

  let history = useHistory();

  function handleClick() {
    history.goBack();
  }


  return (
    <div className="page">
      <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
      <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>
        <Link to={{ pathname: "/home" }}>
          <img src="logo.png"/>
        </Link>
        <div class="MainMenu-btncont"></div>
      </div>

      <div className="page-container">
        <h1>Etapas de Negocio </h1>

        <div className="etapas-menu-container">
          {list?.map((item, i) => (
            <Link className="etapas-menu-item" to={{ pathname: "/etapas/" + item?.Texto }}>
              <img src={"https://drive.google.com/thumbnail?id=" + item?.Imagen}/>
              <p>{item?.Texto}</p>
            </Link>
          ))}
        </div>

        <div className="etapas-text-container">
          {texto?.Texto}
        </div>
      </div>
    </div>
  );
}
