import React, { useState, useEffect } from "react";
import useAuth from "../Auth/useAuth";
import * as aq from "arquero";
import { text, delimiter } from "arquero";
import "./Agendarcuest.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-dropdown-select";
import { useLocation } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Agendarcuest() {
 //guest or not guest
 const user = useAuth();
 var id="";
 for(var i in user.user){
     id=user.user[i]
 }
 var str = id;
 if(str.includes("@")){
   console.log("Si es usuario ")
    str = id.split("@")
    str = str[0]
}


  //get data from sheet
  const [data, setData] = useState();
  const getCsv = async () => {
    var datos = aq.fromCSV(
      await fetch(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vSrk1FXIL841yRQfzBpTdJS8wXzn0f69Rzgy31VwTe0-HE-vk9cRvsEhtfXNuIuxtPOkSy0d_aO0o41/pub?gid=1905198720&single=true&output=csv"
      ).then((res) => res.text())
    );
    var authori = datos.array("Public/Private");
    var tipo = datos.array("Tipo");
    var pregunta = datos.array("Pregunta");
    var opc = datos.array("Opciones dropdown");
    var datadic = [];

    for (let i in authori) {
      datadic.push({
        Authori: authori[i],
        Tipo: tipo[i],
        Pregunta: pregunta[i],
        Opcion: opc[i],
      });
    }

    setData(datadic);
  };
  useEffect(() => {
    if (data == null) {
      getCsv();
    }
  }, [data]);

  //get public/private data
  const [list, setList] = useState(null);
  var checklist = [];
  function getCheck(data) {
    if (id == "guest" || id=="guest1") {
      data?.map((item, i) => {
        if (item?.Authori == "Public") {
          var val = item;
          //dropdown options split
          var dropdic = [];
          if (val.Opcion != null && list == null) {
            var opcArr = [];
            var espacio = "\n";
            opcArr = val.Opcion.split(espacio);
            opcArr = opcArr.filter((a) => a);
            for (i in opcArr) {
              dropdic.push({ label: opcArr[i] });
            }
            val.Opcion = dropdic;
          }

          checklist.push(val);
          setList(checklist);
        }
      });
    } else {
      data?.map((item, i) => {
        var val = item;
        //dropdown options split
        var dropdic = [];
        if (val.Opcion != null && list == null) {
          var opcArr = [];
          var espacio = "\n";
          opcArr = val.Opcion.split(espacio);
          opcArr = opcArr.filter((a) => a);
          for (i in opcArr) {
            dropdic.push({ label: opcArr[i] });
          }
          val.Opcion = dropdic;
        }

        checklist.push(val);
        setList(checklist);
      });
    }
  }

  useEffect(() => {
    getCheck(data);
  }, data);



  //drop down menu
  const [nombre, setNombre] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [puesto, setPuesto] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [notas, setNotas] = useState(null);
  const [resdrop, setResdrop] = useState(null);
  const [date, setDate] = useState(null);
  const [correo, setCorreo] = useState(id);
  const [rescuestionario, setRescuestionario] = useState(null);

  const ddmHandler = (e) => {
    setResdrop(e[0].label);
  };

  const getDate = () => {
    const date = new Date();
    const month =
      date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();

    return (
      month +
      " " +
      date
        .toISOString()
        .split("T")[1]
        .replace(/\.[0-9]+.*/, "")
    );
  };


  //register 
  const [registro, setRegistro]= useState(null);
  
  if(registro==null){
    var reg = new Date();
    let textreg = reg.toString();
    textreg= textreg.replace('GMT-0600 (hora estándar central)',"")
    setRegistro(textreg)
  
  }
 
  const FinalAnswers = (e) => {
    if (
      nombre != null &&
      empresa != null &&
      puesto != null &&
      notas != null &&
      resdrop != null &&
      correo != "guest"
    ) {
      var respuestas = [];
      respuestas.push({
        timestamp: registro,
        nombre: nombre,
        empresa: empresa,
        puesto: puesto,
        correo: correo,
        telefono: telefono,
        notas: notas,
        producto: resdrop,
        fecha: dategmt,
        vendedor: str, 
      });

      setRescuestionario(respuestas);

      // let responseData = pm.response.json();
      //pm.environment.set("HostsToRemove", responseData.resources[0].name);
      fetch(
        "https://api.devel.hal9.com/proxy/https://script.google.com/macros/s/AKfycbznJFYNXzQcOOp8f_CBocCH_HY17h5R_0mQ4WUbmXRBcHZeq28/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(respuestas[0]),
        }
      )
        .then((res) => res.text())
        .catch((e) => {
          console.log(e);
        });
      alert("cita agendada");
    } else {
      alert("Faltan campos ");
    }
  };
  const [dategmt, setDategmt]= useState(null);
  if(date!=null && dategmt==null){
  let text = date.toString();
    text= text.replace('00:00:00 GMT-0600 (hora estándar central)',"")
    setDategmt(text)
  }
  if (rescuestionario != null) {
    console.log("Valor final  ", rescuestionario[0]);
  }

   


  return (
    <div>
      <div className="pregunta-agendar">
        <h4>Nombre</h4>
        <input onChange={(e) => setNombre(e.target.value)}></input>
        <h4>Empresa</h4>
        <input onChange={(e) => setEmpresa(e.target.value)}></input>
        <h4>Puesto</h4>
        <input onChange={(e) => setPuesto(e.target.value)}></input>
        {id == "guest" ? (
          <div>
            <h4>Correo</h4>
            <input onChange={(e) => setCorreo(e.target.value)}></input>
          </div>
        ) : (
          <div></div>
        )}
        <h4>Teléfono(opcional)</h4>
        <input onChange={(e) => setTelefono(e.target.value)}></input>
        <h4>Notas</h4>
        <input onChange={(e) => setNotas(e.target.value)}></input>

        {list?.map((item, i) => (
          <div className="Agendar-pregunta-container">
            {item?.Tipo == "Dropdown" ? (
              <div>
                <h4>{item?.Pregunta}</h4>
                <Select className="agendar-select" options={item.Opcion} onChange={ddmHandler}></Select>
              </div>
            ) : (
              <div />
            )}
          </div>
        ))}

        {list?.map((item, i) => (
          <div className="date-menu-container">
            {item?.Tipo == "Calendario" ? (
              <div>
                <h4>{item?.Tipo}</h4>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  isClearable
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        ))}

        <div className="btn-mandar-container">
          <button className="btn-mandar" onClick={FinalAnswers}>
            Mandar{" "}
          </button>
        </div>
      </div>
    </div>
  );
}
