import React from "react";
import MenuTitle from "../MainComponents/MenuTitle";
import ListaDinamicaExito from "../CasosExitocomp/ListaDinamicaExito";
import { Link, useHistory } from "react-router-dom";
export default function CasosExito() {
  let history = useHistory();

  function handleClick() {
    history.goBack();
  }
  return (
    <>
       <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
          <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>
        <Link to={{ pathname: "/home" }}>
          <img src="logo.png"/>
        </Link>
        <div class="MainMenu-btncont"></div>
      </div>
    </>
  );
}
