import react from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import Login from "../Pages/Login";
import Home from "../Pages/Home";
import AgendarCita from "../Pages/AgendarCita";
import Contacto from "../Pages/Contacto";
import CasosExito from "../Pages/CasosExito";
import Productos from "../Pages/Productos";
import Etapas from "../Pages/EtapasNegocio";
import CasoExitoEspecifico from "../CasosExitocomp/CasodeExito";
import Producto from "../MainComponents/Paginasencilla";
import TipoEtapa from "../Pages/TipodeEtapa";
import MenuEtapa from "../Etapas/Etapa";
import EtapaShow from "../Etapas/Etapashow";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        {/* Routes from Login */}
        <PrivateRoute exact path="/home" component={Home} />

        {/* Routes from /home */}
        <PrivateRoute exact path="/agendar" component={AgendarCita} />
        <PrivateRoute exact path="/contacto" component={Contacto} />
        <PrivateRoute exact path="/productos" component={Productos} />
        <PrivateRoute exact path="/casosexito" component={CasosExito} />
        <PrivateRoute exact path="/etapas" component={Etapas} />

        {/* Casos de exito lista pagina */}
        <PrivateRoute
          exact
          path="/casosexito/:caso"
          component={CasoExitoEspecifico}
        />

        {/* Etapas de negocio paginas */}
        {/* <PrivateRoute exact path="/etapas/:etapa" component={Etapa}/> */}
        <PrivateRoute exact path="/etapas/Optimizar" component={TipoEtapa}/>
        <PrivateRoute exact path="/etapas/Crecer" component={TipoEtapa}/>
        <PrivateRoute exact path="/etapas/Asegurar" component={TipoEtapa}/>
        <PrivateRoute exact path="/:etapa/:etapa/" component={Producto} />
        <PrivateRoute exact path="/etapas/Optimizar/:Etapa" component={EtapaShow}/>
        <PrivateRoute exact path="/etapas/Crecer/:Etapa" component={EtapaShow}/>
        <PrivateRoute exact path="/etapas/Asegurar/:Etapa" component={EtapaShow}/>
        {/* Productos pagina */}
        <PrivateRoute exact path="/productos/:productos" component={Producto}/>
        <PrivateRoute exact path="/productos/producto" />
        <Route path="*" component={() => "404 Not Found"} />
      </Switch>
    </Router>
  );
}
