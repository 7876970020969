import React from "react";
import { useParams } from "react-router";
import useAuth from "../Auth/useAuth";
import MenuTitle from "../MainComponents/MenuTitle";
import Agendarcuest from "../Agendarcitacomp/Agendarcuest";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
//https://www.npmjs.com/package/react-google-forms-hooks

export default function AgendarCita() {
  let history = useHistory();

  function handleClick() {
    history.goBack();
  }
  return (
    <>
      <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
      <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>      
      <Link to={{ pathname: "/home" }}>
        <img src="logo.png"/>
      </Link>
      <div class="MainMenu-btncont"></div>
    </div>
      <Agendarcuest />
    </>
  );
}
