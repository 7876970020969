import React from "react";
import useAuth from "../Auth/useAuth";

import HomePage from "../Home/Home";

export default function Home() {
  const user = useAuth();

  console.log("usuario autorizado", user);

  return (
    <HomePage />
  );
}
