import React from "react";
import { Redirect, Route } from "react-router";
import useAuth from "../Auth/useAuth";

export default function PrivateRoute({ component: Component, ...rest }) {
  const user = useAuth();

  return (
    <Route {...rest}>
      {user.isLogged() ? <Component /> : <Redirect to="/" />}
    </Route>
  );
}
