import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as aq from "arquero";
import { text, delimiter } from "arquero";
import { useHistory } from "react-router";
import "./Contacto.css";

export default function Contacto() {
  const [data, setData] = useState(null);

  const getCsv = async () => {
    const csv = await fetch(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vSrk1FXIL841yRQfzBpTdJS8wXzn0f69Rzgy31VwTe0-HE-vk9cRvsEhtfXNuIuxtPOkSy0d_aO0o41/pub?gid=183579858&single=true&output=csv"
      ).then((res) => res.text());

    const data = aq.fromCSV(csv);
    const dataDic = [{
      id: 1,
      Correo: data.array("Correo")[0],
      Telefono: data.array("Teléfono")[0],
      Direccion: data.array("Dirección")[0],
    }];

    setData(dataDic);
  };

  useEffect(() => {
    if (!data) getCsv();
  }, [data]);

  if (data) {
    data.map((item, i) => {
      console.log(item.Correo);
    });
  }
  let history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <div className="page">
      <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
      <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>
        <Link to={{ pathname: "/home" }}>
          <img src="logo.png"/>
        </Link>
        <div class="MainMenu-btncont"></div>
      </div>

      <div className="page-container">

        <div>
          {data?.map((item) => (
            <div key={item.id}>
              <h4>{item.Correo}</h4>
              <h4>{item.Telefono}</h4>
              <h4>{item.Direccion}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
