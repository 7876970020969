import React from "react";
import { useLocation } from "react-router";
import MenuTitle from "../MainComponents/MenuTitle";
import { Link, useHistory } from "react-router-dom";
export default function CasoExitoEspecifico(props) {
  let history = useHistory();

  function handleClick() {
    history.goBack();
  }
 

  return (
    <>
     <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
          Regresar Img
        </button>
        <Link to={{ pathname: "/home" }}>
          <img src="logo.png"/>
        </Link>
      </div>
    </>
  );
}
