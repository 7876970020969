import React, { useState } from "react";
import { useHistory, Redirect } from "react-router";
import { Link } from "react-router-dom";

import GoogleLogin from "react-google-login";
import { useGoogleLogin } from "react-google-login";

import useAuth from "../Auth/useAuth";
import history from "../history";

import "./Login.css";

export default function Login() {
  const dominios = ["@up.edu.mx", "@yahoo.com.mx", "@hal9.ai"];
  const history = useHistory();

  const user = useAuth();

  const [id, setId] = useState("guest");
  const [email, setEmail] = useState("guest");
  const [band, setBand] = useState(null);

  const handleLogin = () => {
    // alert("has entrado como invitado");
    user.login(id, email);
    setBand(1);
  };

  const responseGoogle = (res) => {
    let b = false;

    const { email } = res.profileObj;
    console.log("email dentro ", email);

    for (let i in dominios) {
      if (email.includes(dominios[i])) {
        b = true;
        user.login(res.profileObj.googleId, res.profileObj.email);
        setBand(1);
      }
    }
    if (b) {
      alert("Correo autorizado, bienvenido: " + res.profileObj.givenName);
    } else {
      alert("Tu correo no esta autorizado, verifica el @dominio.com");
    }
  };

  if (band != null) {
    console.log("Puedes acceder");
    return <Redirect to="/home"></Redirect>;
  }

  return (
    <div className="page">
      <div className="page-title">
        <img src="logo.png"/>
      </div>
      <div className="page-container center">
        <button className="btn-login" onClick={handleLogin}>
          Ingresar como invitado
        </button>
        <GoogleLogin
          clientId="769239539248-kmnrr30voojad5ql6ldjrk7h7e90u256.apps.googleusercontent.com"
          render={(renderProps) => (
            <button
              className="btn-login"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              Iniciar con correo
            </button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          isSignedIn={false}
          redirectUri="/"
          cookiePolicy={"single_host_origin"}
        />
      </div>
    </div>
  );
}
