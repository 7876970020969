import React, { useEffect, useState } from "react";
import useAuth from "../Auth/useAuth";
import { Link } from "react-router-dom";
import "../MainComponents/Menu.css";
import * as aq from "arquero";
import { useHistory } from "react-router";
export default function EtapaShow() {
 
    var loc = window.location.href
    var gid = null;


    //local
    if(loc.includes("http://localhost:3000/#/etapas/Optimizar")){
     loc= loc.replace("http://localhost:3000/#/etapas/Optimizar/","")
     gid=1111133780
    }
    else if (loc.includes("http://localhost:3000/#/etapas/Crecer")){
         loc = loc.replace("http://localhost:3000/#/etapas/Crecer/","")
         gid=717445618
    }
    else if (loc.includes("http://localhost:3000/#/etapas/Asegurar")){
        loc = loc.replace("http://localhost:3000/#/etapas/Asegurar/","")
        gid=1194810521
   }
   
   //eexternal
   if(loc.includes("https://kam-app.com/#/etapas/Optimizar")){
    loc= loc.replace("https://kam-app.com/#/etapas/Optimizar/","")
    gid=1111133780
   }
   else if (loc.includes("https://kam-app.com/#/etapas/Crecer")){
        loc = loc.replace("https://kam-app.com/#/etapas/Crecer/","")
        gid=717445618
   }
   else if (loc.includes("https://kam-app.com/#/etapas/Asegurar")){
       loc = loc.replace("https://kam-app.com/#/etapas/Asegurar/","")
       gid=1194810521
  }

   var find = '%20';
   var re = new RegExp(find, 'g');
   loc= loc.replace(re," ")
   
     //guest or not guest
     const user = useAuth();
     var id="";
     for(var i in user.user){
         id=user.user[i]
     }
     //get products sheets
    const [data, setData]= useState(null);
    const getCsv=async()=>{
        var datos= aq.fromCSV(await fetch("https://docs.google.com/spreadsheets/d/e/2PACX-1vSrk1FXIL841yRQfzBpTdJS8wXzn0f69Rzgy31VwTe0-HE-vk9cRvsEhtfXNuIuxtPOkSy0d_aO0o41/pub?gid="+gid+"&single=true&output=csv").then(res => res.text()));
        var authori = datos.array("Public/Private")
        var nombre = datos.array("Nombre")
        var imgheader = datos.array("ImagenHeader")
        var texto = datos.array("Texto")
        var btn1 = datos.array("Boton1")
        var btn2= datos.array("Boton2")
        var btn3 = datos.array("Boton3")
        var galeria = datos.array("Galería")
        var urlgale=datos.array("URLs-Galería")
        var urlbtn1 = datos.array("URL-Boton1")
        var urlbtn2 = datos.array("URL-Boton2")
        var urlbtn3 = datos.array("URL-Boton3")
        var bandtext = datos.array("Imagen/Texto")
       
        
        var datadic=[]

        for(let i in authori){
            datadic.push({Authori:authori[i],Nombre:nombre[i], Imgheader:imgheader[i]
                ,Texto:texto[i],Boton1:btn1[i],Boton2:btn2[i],Boton3:btn3[i],Galeria:galeria[i],
                urlgale:urlgale[i],urlbtn1:urlbtn1[i],urlbtn2:urlbtn2[i],
                urlbtn3:urlbtn3[i],bandtext:bandtext[i]
            
            })
        }
        setData(datadic);

    }


    useEffect(()=>{
        if(data==null){
            getCsv()
        }
    },[data]);
    var list=""
    var imgheader = ""
    const [galeria,setGaleria] = useState(null);
    for(let i in data){
        if(data[i].Nombre==loc){
            list=data[i]
            list.Imgheader = data[i].Imgheader.replace('https://drive.google.com/file/d/', '')
            list.Imgheader = data[i].Imgheader.replace('/view?usp=sharing','')
            if(list.Galeria){
                var GaleriaArreglo=[]
                var espacio ="https://drive.google.com/file/d/";
                GaleriaArreglo = list.Galeria.split(espacio)
                GaleriaArreglo = GaleriaArreglo.filter((a) => a);
                for(let i in GaleriaArreglo){
                    GaleriaArreglo[i]=GaleriaArreglo[i].replace('https://drive.google.com/file/d/', '')
                    GaleriaArreglo[i]=GaleriaArreglo[i].replace('/view?usp=sharing','')
                    GaleriaArreglo[i]=GaleriaArreglo[i].replace("\n","")
                }
                if(galeria==null){
                    setGaleria(GaleriaArreglo)
                }
                
            }
        }
    }
    var etapasband=1;
    let history = useHistory();

    function handleClick() {
      history.goBack();
    }
    
    var result=[]

    var ejemplo =list.Texto?.split("\n")
   var title =[]
   var text=[]
   var ejemm=[]
   var Textobj=""
    for(var i in ejemplo){
      if(ejemplo[i].includes("[")){
        var aux=""
        aux= ejemplo[i].replace("]", '')
        title.push(aux.replace("[", '')) //solo descomentar esta linea
      //  aux =aux.replace("[", '')
      }
      else{
        text.push(ejemplo[i])
      }
    }



  return (
    <div className="page">
    <div className="page-title">
    <button className="MainMenu-btncont" onClick={handleClick}>
          <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>
      <Link to={{ pathname: "/home" }}>
        <img src="logo.png"/>
      </Link>
      <div class="MainMenu-btncont"></div>
    </div>

    <div className="page-container">
      <div className="pagina-title">
        {list?.bandtext=="Texto" ? (
          <h3>
              {list?.Nombre}
          </h3>
        
        ) : (
          <img
              src={
                "https://devel.hal9.com/proxy/https://lh3.googleusercontent.com/d/" +
                list?.Imgheader +
                "=s220?authuser=0"
              }
            />
        )}
      </div>

      <div className="pagina-content">
        {/*ejemplo2 */}
        <div>
          {
            ejemplo?.map((id,i)=>(
              <div dangerouslySetInnerHTML={{ __html: id?.replace(/[¶]/g,"<br />").replace(/[[]/g,"<b/>") }}></div>
            ))
          }
        </div>
     {/*<div dangerouslySetInnerHTML={{ __html: list.Texto?.replace(/\n/g,"<br />").replace(/[[]/g,"<b>").replace(/[*]/g,"<b/>") }}></div>*/}
        {etapasband ? (
          <div className="pagina-galeria">
            {galeria?.map((id, i) => (
              <Link
                to={{pathname:"/productos/"+id,}}
              >
                <img
                  src={
                    "https://hal9.com/proxy/https://lh3.googleusercontent.com/d/" +
                    id 
                  }
                />
              </Link>
            ))}
          </div>
        ) : (
          <div className="pagina-galeria">
            {galeria?.map((id) => (
              <img
                src={
                  "https://devel.hal9.com/proxy/https://lh3.googleusercontent.com/d/" +
                  id + "=s220?authuser=0"
                }
              />
            ))}
          </div>
        )}

        <div className="btns-container">
          {list?.Boton1 &&
            <button>
              <a href={list?.urlbtn1} target="_blank">
                {list.Boton1}
              </a>
            </button>
          }
          {list?.Boton2 &&
            <button>
              <a href={list?.urlbtn2} target="_blank">
                {list.Boton2}
              </a>
            </button>
          }
          {list?.Boton3 &&
            <button>
              <a href={list?.urlbtn3} target="_blank">
                {list.Boton3}
              </a>
            </button>
          }
        </div>
      </div>
    </div>
  </div>
  );
}
