import { createContext, useState } from "react";
import { id } from "date-fns/locale";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ id: "guest1", email: "guest1" });
  // const [user, setUser] = useState({ id:1 });

  const contextValue = {
    user,

    login(id, email) {
      setUser({ id: id, email: email });
    },

    isLogged() {
      return !!user;
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
