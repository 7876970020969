import React from "react";
import { Link } from "react-router-dom";

import useAuth from "../Auth/useAuth";

import "./Home.css";

export default function Home() {
  const user = useAuth();

  let id = "";
  for (var i in user.user) {
    id = user.user[i];
  }
  console.log("Usuario", id);

  return (
    <div className="page">
      <div className="page-title">
        <img src="logo.png"/>
      </div>

      <div className="page-container">
        <div className="home-menu-item">
          <Link to={{ pathname: "/etapas", state: { pagina: "etapas", user: id }}}>
            <img src="transform_icon.png"/>
            <span>Etapas de Negocio</span>
          </Link>
        </div>

        <div className="home-menu-item">
          <Link to={{ pathname: "/casosexito", state: { pagina: "casos", user: id }}}>
            <img src="Icono_casos de exito.png" />
            <span>Casos de éxito</span>
          </Link>
        </div>

        <div className="home-menu-item">
          <Link to={{ pathname: "/productos" }}>
            <img src="productos_icon.png" />
            <span>Productos</span>
          </Link>
        </div>

        <div className="home-menu-item">
          <Link to={{ pathname: "/agendar", state: { user: id } }}>
            <img src="agendar_icon.png" />
            <span>Agendar Cita</span>
          </Link>
        </div>

        <div className="home-menu-item">
          <Link to={{ pathname: "/contacto", user: id }}>
            <img src="contacto_icon.png" />
            <span>Contacto</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
