import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as aq from "arquero";
import useAuth from "../Auth/useAuth";
import "./Productos.css";
import { useHistory } from "react-router";
export default function Producto() {
  const { href} =window.location;
  var gid =null
  var linkname=href;
  if(href.includes('productos')){
      gid=513062738
          if(linkname.includes('http://localhost:3000/')){
          linkname=linkname.replace('http://localhost:3000/', '') } 
          else{
              linkname = linkname.replace('https://kam-app.com/','')
          }
  }
  //guest or not guest
  const user = useAuth();
   var id="";
   for(var i in user.user){
       id=user.user[i]
   }

  //get products sheets
  const [data, setData]= useState(null);
  const getCsv=async()=>{
      var datos= aq.fromCSV(await fetch("https://docs.google.com/spreadsheets/d/e/2PACX-1vSrk1FXIL841yRQfzBpTdJS8wXzn0f69Rzgy31VwTe0-HE-vk9cRvsEhtfXNuIuxtPOkSy0d_aO0o41/pub?gid="+gid+"&single=true&output=csv").then(res => res.text()));
      var authori = datos.array("Public/Private")
      var nombre = datos.array("Nombre")
      var imgheader = datos.array("ImagenHeader")
      var texto = datos.array("Texto")
      var btn1 = datos.array("Boton1")
      var btn2= datos.array("Boton2")
      var btn3 = datos.array("Boton3")
      var galeria = datos.array("Galería")
      var urlgale=datos.array("URLs-Galería")
      var urlbtn1 = datos.array("URL-Boton1")
      var urlbtn2 = datos.array("URL-Boton2")
      var urlbtn3 = datos.array("URL-Boton3")
      
      
      var datadic=[]

      for(let i in authori){
          datadic.push({Authori:authori[i],Nombre:nombre[i], Imgheader:imgheader[i]
              ,Texto:texto[i],Boton1:btn1[i],Boton2:btn2[i],Boton3:btn3[i],Galeria:galeria[i],
              urlgale:urlgale[i],urlbtn1:urlbtn1[i],urlbtn2:urlbtn2[i],
              urlbtn3:urlbtn3[i],
          
          })
      }
      setData(datadic);

  }

  useEffect(()=>{
      if(!data){
          getCsv()
      }
  },[data]);


  //get public/private data
  const [list,setList]= useState(null);
  var checklist=[]

  function getCheck(data){

      if(id==="guest" || id==="guest1"){
          data?.map((item,i)=>{
              if(item.Authori=="Public"){
                  var val = item;
                  ////get id to show drive image
                  val.Imgheader = val.Imgheader.replace('https://drive.google.com/file/d/', '')
                  val.Imgheader = val.Imgheader.replace('/view?usp=sharing','')
                  checklist.push(val)
                  setList(checklist)
              }

          })
      }
      else{
          data?.map((item,i)=>{
              if(item.Authori==="Public" || item.Authori==="Private"){
                  var val = item;
                  ////get id to show drive image
                  val.Imgheader = val.Imgheader.replace('https://drive.google.com/file/d/', '')
                  val.Imgheader = val.Imgheader.replace('/view?usp=sharing','')
                  checklist.push(val)
                  setList(checklist)
              }
          })
      }

  }

  useEffect(()=>{
      getCheck(data)
  },data)

  let history = useHistory();

  function handleClick() {
    history.goBack();
  }
  return (
    <div className="page">
      <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
          <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>  
        <Link to={{ pathname: "/home" }}>
          <img src="logo.png"/>
        </Link>
        <div class="MainMenu-btncont"></div>
      </div>

      <div className="page-container">
        {list?.map((item) => (
          <div className="home-menu-item producto-menu-item" key={item.id}>
            <Link to={{pathname:"/productos/"+item?.Imgheader,
                            state:{ item
                            }}}>
              <img
                src={
                  "https://devel.hal9.com/proxy/https://lh3.googleusercontent.com/d/" +
                  item?.Imgheader +
                  "=s220?authuser=0"
                }
              />
              <span>{item?.Nombre}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
