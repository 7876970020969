import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import * as aq from "arquero";
import { useHistory } from "react-router";
import "./Paginasencilla.css";

export default function Producto() {
  var loc = window.location.href
    if(loc.includes("http://localhost:3000/#/productos/")){
    loc=    loc.replace("http://localhost:3000/#/productos/","")}
    else{
      loc=    loc.replace("https://kam-app.com/#/productos/","")
    }
    var bandmenu=null;
    if(loc.includes("etapas")){
        var bandmenu=1;
        
    }
    var etapasband=null;
    const [datam, setDatam]= useState(null);
    //get products sheets
    const getCsv=async()=>{
        var datos= aq.fromCSV(await fetch("https://docs.google.com/spreadsheets/d/e/2PACX-1vSrk1FXIL841yRQfzBpTdJS8wXzn0f69Rzgy31VwTe0-HE-vk9cRvsEhtfXNuIuxtPOkSy0d_aO0o41/pub?gid=513062738&single=true&output=csv").then(res => res.text()));
        var authori = datos.array("Public/Private")
        var nombre = datos.array("Nombre")
        var imgheader = datos.array("ImagenHeader")
        var texto = datos.array("Texto")
        var btn1 = datos.array("Boton1")
        var btn2= datos.array("Boton2")
        var btn3 = datos.array("Boton3")
        var galeria = datos.array("Galería")
        var urlgale=datos.array("URLs-Galería")
        var urlbtn1 = datos.array("URL-Boton1")
        var urlbtn2 = datos.array("URL-Boton2")
        var urlbtn3 = datos.array("URL-Boton3")
        
        
        var datadic=[]

        for(let i in authori){
            datadic.push({Authori:authori[i],Nombre:nombre[i], Imgheader:imgheader[i]
                ,Texto:texto[i],Boton1:btn1[i],Boton2:btn2[i],Boton3:btn3[i],Galeria:galeria[i],
                urlgale:urlgale[i],urlbtn1:urlbtn1[i],urlbtn2:urlbtn2[i],
                urlbtn3:urlbtn3[i],
            
            })
        }
        setDatam(datadic);

    }

    useEffect(()=>{
        if(datam==null){
            getCsv()
        }
    },[datam]);

   
    var list=""
    var imgheader = ""
    for(let i in datam){
        if(datam[i].Imgheader.includes(loc)){
            list=datam[i]
            list.Imgheader = datam[i].Imgheader.replace('https://drive.google.com/file/d/', '')
            list.Imgheader = datam[i].Imgheader.replace('/view?usp=sharing','')
            
        }
    }

    
    

    const [galeria,setGaleria] = useState(null);
    //get id of galeria to use it in images
    if(list.Galeria==null   ){}
    else{
        var GaleriaArreglo=[]
        var espacio ="https://drive.google.com/file/d/";
        GaleriaArreglo = list.Galeria.split(espacio)
        GaleriaArreglo = GaleriaArreglo.filter((a) => a);
        
        for(let i in GaleriaArreglo){
            GaleriaArreglo[i]=GaleriaArreglo[i].replace('https://drive.google.com/file/d/', '')
            GaleriaArreglo[i]=GaleriaArreglo[i].replace('/view?usp=sharing','')
            GaleriaArreglo[i]=GaleriaArreglo[i].replace("\n","")
        }
        if(galeria==null){
            setGaleria(GaleriaArreglo)
        }

    }
    let history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <div className="page">
      <div className="page-title">
      <button className="MainMenu-btncont" onClick={handleClick}>
        <img
            src={
              "https://drive.google.com/uc?export=view&id=1axbwmBMX8NRatLs6bjRzv6mUaZxYYMEC" 
            }></img>
        </button>  
        <Link to={{ pathname: "/home" }}>
          <img src="logo.png"/>
        </Link> 
        <div class="MainMenu-btncont"></div>
      </div>

      <div className="page-container">
        <div className="pagina-title">
          {!etapasband ? (
            
            <a href={list?.urlgale} target="_blank">
              <img
                src={
                  
                    "https://hal9.com/proxy/https://lh3.googleusercontent.com/d/" +
                    list?.Imgheader 
                }
              />
            </a>
          ) : (
            <img
              src={
                "https://drive.google.com/uc?export=view&id=" +
                list?.Imgheader 
              }
            />
          )}
        </div>

        <div className="pagina-content">
       <div dangerouslySetInnerHTML={{ __html: list.Texto?.replace(/\n/g,"<br />") }}></div>
          {etapasband ? (
            <div className="pagina-galeria">
              {galeria?.map((id, i) => (
                <Link
                  to={{
                    pathname: "/#/p1roductoss-producto",
                    
                  }}
                >
                  <img
                    src={
                      "https://devel.hal9.com/proxy/https://lh3.googleusercontent.com/d/" +
                      id + "=s220?authuser=0"
                    }
                  />
                </Link>
              ))}
            </div>
          ) : (
            <div className="pagina-galeria">
              {galeria?.map((id) => (
                <img
                  src={
                    "https://devel.hal9.com/proxy/https://lh3.googleusercontent.com/d/" +
                    id + "=s220?authuser=0"
                  }
                />
              ))}
            </div>
          )}

          <div className="btns-container">
            {list?.Boton1 &&
              <button>
                <a href={list?.urlbtn1} target="_blank">
                  {list.Boton1}
                </a>
              </button>
            }
            {list?.Boton2 &&
              <button>
                <a href={list?.urlbtn2} target="_blank">
                  {list.Boton2}
                </a>
              </button>
            }
            {list?.Boton3 &&
              <button>
                <a href={list?.urlbtn3} target="_blank">
                  {list.Boton3}
                </a>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
